.tabs__container {
  .tabs__header {
    display: flex;
    flex-wrap: wrap;
    background-color: #e0e0e0;
    border-radius: 8px;
    padding: 8px;
    margin-left: 1rem;
    margin-right: 1rem;
    gap: 1rem;

    .tabs__header__item {
      cursor: pointer;
      border: none;
      transition: all 0.3s ease;
      font-size: 1.2rem;
      font-weight: bold;
      padding: 8px 1rem;
      border-radius: 5px;
      background-color: #e0e0e0;
      color: #08070783;

      &:hover {
        background-color: #e15615;
        color: white;
      }

      &.active {
        background-color: #e15615;
        color: white;
      }
    }
  }

  .tabs__content {
    padding: 1rem;

    .tabs__content__item {
    }
  }
}
