.piani-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px dashed #e15615;
  border-radius: 6px;
  padding: 8px;
  width: 100%;
  margin-top: 10px;

  .piano-remove-icon {
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
    border: none;
    background-color: white;
  }

  .block-section-header {
    margin-top: 16px;
  }

  .scaffali-section {
    padding: 8px;
    border-radius: 6px;
    border: 1px dotted #e0e0e0;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .scaffali-container {
      position: relative;
      display: flex;
      flex-direction: column;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      padding: 8px;
      width: 100%;

      .scaffale-remove-icon {
        position: absolute;
        top: 4px;
        right: 4px;
        cursor: pointer;
        border: none;
        background-color: white;
      }
    }
  }
}
