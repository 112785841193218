.autorizzazione-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-weight: 400;
  font-size: 18px;

  label.autorizzazione-icon-wrapper {
    cursor: pointer;
  }

  .autorizzazione-icon-wrapper {
    border: none;
    background: white;
    margin-left: auto;
    width: 24px;
    height: 24px;
  }
}

.bibliografia-pdf-container {
    padding: 0.5rem 1rem;
    border-radius: 8px;
    border: 1px solid #e15615;
    background-color: white;
    width: fit-content;
    max-width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    button {
      background-color: white;
      border: none;
      cursor: pointer;

      &:hover > p {
        text-decoration: underline;
      }

      p {
        color: #e15615;
        font-weight: 700;
        text-align: center;
        margin-top: 4px;
      }

      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    .pdf-close-icon {
      position: absolute;
      top: 6px;
      right: 6px;
      background: white;
      border-radius: 50%;
      cursor: pointer;

      &.disabled {
        display: none;
      }
    }
  }
