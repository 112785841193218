.image-gallery-main-section {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;

  .file-upload-landing {
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 40px;
    text-align: center;
    transition: background-color 0.3s ease;

    &.dragging {
      background-color: #e0f7fa;
    }

    .upload-icon {
      margin-bottom: 20px;
    }

    .instruction-text {
      color: #666;
      font: 400 14px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
      margin-bottom: 10px;
    }

    .upload-text {
      color: #333;
      font: 400 16px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
      margin-bottom: 10px;
    }

    .upload-button {
      display: inline-block;
      padding: 10px 20px;
      background-color: #e15615;
      color: #fff;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #d34b0b;
      }

      input {
        display: none;
      }
    }
  }

  .images-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
  }

  .image-set {
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;
    border: 1px solid #e15615;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .image-set-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease;

      .overlay-icon {
        background: none;
        border: none;
        color: #fff;
        cursor: pointer;
        margin: 0 10px;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    &:hover {
      .image-set-overlay {
        opacity: 1;
      }
    }

    .main-image {
      inset: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      display: block;
    }
  }
}

// ThreeJSContainer
.three-scene-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  .three-container {
    position: relative;
    border: 1px solid #e15615;
    .three-expand-btn {
      position: absolute;
      cursor: pointer;
      border: none;
      background: transparent;
      padding: 4px;
      background-color: #e15615;
      border-radius: 100%;
      width: 24px;
      height: 24px;

      &.toggle {
        top: -10px;
        left: -5px;
      }

      &.delete {
        top: -10px;
        right: -5px;
      }
    }
  }

  .three-light-intensity {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: 0 10px;

    input {
      width: 100%;
    }
  }
}
