button.toggle-cassetta-button {
  background-color: #e15615;
  color: white;
  padding: 8px 12px;
  font-weight: 600;
}

.esposizione-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .new-location-container {
    display: flex;
    .new-location-btn {
      background-color: #e15615;
      color: white;
      padding: 8px 12px;
      font-weight: 600;
      border: none;
      cursor: pointer;
      border-radius: 6px;
    }
  }
}

.new-location-modal-container {
  padding: 1rem;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .new-location-modal-header {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    justify-content: space-between;
  }

  .location-add-btn,
  .location-remove-btn {
    background: white;
    border: none;
    width: 24px;
    height: 24px;
  }

  .location-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;

    input {
      width: 100%;
      padding: 1rem;
      border: 1px solid #e5e5e5;
      border-radius: 6px;
    }
  }

  @media screen and (max-width: 600px) {
    min-width: 100%;
  }
}
