.movimentazione-section-table {
  width: 100%;
  .table-actions {
    display: flex;
    align-items: center;
    gap: 6px;

    & > button {
        background: white;
        border: none;
        cursor: pointer;
    }
  }
}
