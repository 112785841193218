#annotations {
  order: 4;
}

#generalità {
  order: 1;
}
#posizione {
  order: 3;
}
#raccolte {
  order: 2;
  grid-row: span 3;
}

@media (max-width: 768px) {
  #annotations {
    order: 4;
  }
  #generalità {
    order: 1;
  }
  #posizione {
    order: 2;
  }
  #raccolte {
    order: 3;
  }
}

.posizione-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: bottom;
}

.posizione-row-field {
  width: 80%;
}

.posizione-row-icon {
  margin-top: 2rem;
}

.form-block .annotations {
  display: flex;
  gap: 10px;
}
