.tbl-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f2f2f2;
  border-radius: 6px;
  width: calc(100% - 40px);
  height: calc(100% - 200px);
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 520px) {
    height: calc(100% - 160px);
   }
}

.tbl-scrollable-content {
  overflow-y: auto;
  flex-grow: 1;
  height: 100%;

  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    background-color: rgba(128, 128, 128, 0.252);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e15615;
  }
}

.tbl-sub-container {
  display: inline-block! important;
  width: 100%!important;
  height: auto!important;
  overflow: visible!important;
}


.tbl-header {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  flex-direction: row!important;
  // background-color: #e6e6e6;
  padding: 20px;
  font-size: 18px;
  color: #000;
  font-weight: 400;
  flex: 1;
  height: 100%;
  border-bottom: 1px solid rgba(221, 221, 221, 1);
}



.tbl-header-item {
  display: flex;
  flex-direction: row;
  white-space: normal;
  gap: 0.1rem;
  word-wrap: break-word;
  align-items: left;
  flex-shrink: 0; /* Prevent shrinking */
  margin-left: 0.1rem;
  margin-right: 1rem;
}

.tbl-header-item-text {
  font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
  margin: auto 0;
}

.tbl-row {
  display: flex;
  width: 100%;
  flex-direction: row!important;
  padding: 20px;
  font-size: 18px;
  color: #000;
  font-weight: 400;
  flex: 1;
  height: 100%;
  border-bottom: 0.1rem solid rgba(221, 221, 221, 1);
}



.tbl-row-item {
  display: flex;
  flex-direction: row;
  white-space: normal;
  gap: 0.1rem;
  word-wrap: break-word;
  align-items: left;
  flex-shrink: 0; /* Prevent shrinking */
  margin-left: 0.1rem;
  margin-right: 1rem;
}


.tbl-row-item-text {
  font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
  margin: auto 0;
}

.tbl-header-icon {
  width: 28px;
  height: 28px;
  object-fit: contain;
  cursor: pointer;
}


.tbl-row-icon {
  width: 28px;
  height: 28px;
  align-self: stretch;
  margin: auto 0;
  object-fit: contain;
}

.tbl-fixed-bottom {
  bottom: 0;
  width: 100%;
  background-color: #fff;
  // padding: 10px 0;
  border-radius: 0 0 6px 6px;
  position: sticky;
  height: 64px;
}


