@import "./variable.scss";

.blocks-container {
  display: grid;
  grid-template-columns: 1fr; /* Single column layout for mobile */
  gap: 1rem;
  width: 100%;
  margin-bottom: 3rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    grid-auto-rows: auto; /* Ensure rows are sized based on content */
    grid-auto-flow: dense; /* Fill in gaps with smaller items */
    align-items: start; /* Align items to the start of the grid row */
  }
}

.form-block {
  min-width: 400px;
  height: 100%;
  max-height: none;
  align-self: start;
  box-sizing: border-box;
  box-shadow: 1px 3px 2px 2px #aaaa;
  background-color: $archeo-bg-color;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 16px;
}

.form-block-button {
  display: flex;
  align-items: center;
  gap: $button-gap;
  margin: $button-margin;
  padding: $button-padding;
  color: $archeo-text-color;
  border: $button-border;
  border-radius: $button-radius;
  cursor: pointer;
  background-color: $archeo-color;
  font: $button-font-weight 14px $font-family;
}

.section-title {
  font: 400 24px $font-family;
  color: $archeo-gray;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.form-block-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 1rem;
}

.form-block-header-title {
  font: 400 24px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
  color: $archeo-gray;
  text-transform: uppercase;
}

.input-fields-column {
  display: flex;
  gap: 20px;
  width: 100%;
}

@media (max-width: 520px) {
  .input-fields-column {
    width: 100%;
    flex-direction: column;
  }
}

.input-box {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;

  input {
    width: 100%;
  }
}

.input-field {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  color: #000;
  font-weight: 400;
  letter-spacing: 0.36px;
}

.input-label {
  font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
}

.input-value {
  font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 1);
  margin-top: 8px;
  padding: 12px 20px;
  background-color: "#fffff";
  height: 48px;
}

.input-value:disabled {
  background-color: #dddddd;
}
@media (max-width: 520px) {
  .input-value {
    white-space: initial;
  }
}
