$font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;

// button variables
$button-font-size: 14px;
$button-font-weight: 400;
$button-radius: 6px;
$button-padding: 0.5rem 1rem;
$button-gap: 0.5rem;
$button-margin: 0.5rem;
$button-padding: 6px 12px;
$button-border: 0.15rem solid $archeo-color;