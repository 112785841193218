.class-add-btn-container {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.class-add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: auto;
}

.class-add-btn-icon {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
}

.class-summary-table-container {
  margin-top: 1rem;
  padding: 0.5rem;
}

.class-summary-table-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.class-summary-table {
  overflow: hidden;
  overflow-x: auto;
}

.class-summary-table table {
  width: 100%;
  border-collapse: collapse;
}

.class-summary-table table thead tr th {
  padding: 0.5rem 0;
}

.class-summary-table table tr {
  border-bottom: 1px solid #ddd;
}

.class-summary-table table tr td {
  padding: 0.5rem 1rem;
  font-weight: 500;
  color: #000000b9;
}

.class-summary-table table tr:last-child td {
  font-weight: bold;
}

.class-summary-table table .table-action {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.class-summary-table table .table-action.hidden {
  display: none;
}

.class-summary-table table .table-action button {
  margin: 0;
}

.pluss-icon {
  width: 20px;
}

.class-button {
  margin-right: 0%;
  border: 0;
  cursor: pointer;
  background-color: transparent
}