.process-container {
  width: 450px;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;

  & > h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #e15615;
  }

  .progress-wrapper {
    display: flex;
    width: 95%;
    // justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;

    .process-spinner {
      border: 3px solid rgba(0, 0, 0, 0.1);
      border-left-color: #e15615;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      min-width: 20px;
      position: absolute;
      left: 90%;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }

    .progress-bar {
      position: relative;
      width: 100%;
      background-color: #f3f3f3;
      border: 1px solid #ccc;
      border-radius: 5px;
      overflow: hidden;
      height: 1rem;

      .progress {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        &.success-progress {
          background-color: #4caf50;
        }

        &.error-progress {
          background-color: #f44336;
          left: auto;
          right: 0;
        }
      }
    }
  }

  .process-report-container {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;

    .process-report-wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}
